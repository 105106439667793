<template>
  <div class="date-duration">
    <div>Calculate the period between two dates</div>
    <dates-duration></dates-duration>
  </div>
</template>
<script>
import DatesDuration from "../components/DatesDuration";
export  default {
  name:"DatePage",
  components: {DatesDuration}
}
</script>
<style scoped>
.date-duration > div:first-child {
  margin-bottom: 10px;
}
</style>
