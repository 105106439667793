<template>
<section>
  <div>
    <div>
      <label class="user-select-none" name="beginDate" :for="id('beginDate')">Begin date</label>
      <input name="beginDate" :id="id('beginDate')" v-model="dates.begin" type="date" required/>
     <label class="user-select-none" name="endDate" :for="id('endDate')">End date</label>
     <input name="endDate" :id="id('endDate')" v-model="dates.end" type="date" required/>
   </div>
    <button :disabled="!(dates.end && dates.begin)" :class="{disabled:!(dates.end && dates.begin)}" class="user-select-none" @click.prevent="calculatePeriod">Calculate</button>
  </div>
  <div v-show="datesDuration"><div>There are</div> <div class="date-duration-content"><div>{{datesDuration}}</div><div>or</div><div>{{totalDays}}</div></div> <div>between these dates (excluding the end date)</div></div>
</section>
</template>

<script>
import debounce from "lodash.debounce";
export default {
  name: "DatesDuration",
  data(){
    return {
      dates: {begin:"", end:""},
      datesDuration: "",
      totalDays: ""
    }
  },
  computed:{
    id(){
      return (inputLabel) => {
        return inputLabel + "_" + this.uuid
      }
    },
    uuid(){
      return "date_durations"
    }
  },
  watch:{
    'dates.begin'(newDate,oldDate){
      if(newDate != oldDate){
        this.datesDuration = ""
      }
    },
    'dates.end'(newDate,oldDate){
      if(newDate != oldDate){
        this.datesDuration = ""
      }
    }
  },
  methods:{
    calculatePeriod: debounce(function(){
      if(this.dates.begin && this.dates.end){
        const url = this.$store.state.apiConfig + "/period/" + this.dates.begin + "/" + this.dates.end
        this.$http.get(url).then(response =>{
          const data = response.data
          this.datesDuration = " "+ data.years + " year(s) " + data.months + " month(s) " +
              data.days + " day(s)"
          this.totalDays = data.totalDays + " day(s)"
        }).catch(() =>{
          this.datesDuration = ""
        })
      }
    },1000)
  }
}
</script>

<style scoped>
label, input, button {
  padding: 4px;
  margin: 10px;
}
label:hover, input:hover, button:not(.disabled):hover {
  cursor: pointer;
  color: #08a5e0;
}
button:not(.disabled):hover {
  box-shadow: 1px 1px 2px #08a5e0;
}
button {
  box-shadow: 1px 1px 2px grey;
  border-radius: 5px;
  border: none;
  min-width: 100px;
  min-height: 35px;
  outline: none;

}
section > div:nth-child(2){
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
  padding: 10px;
}
section > div:nth-child(2) > div:nth-child(2) {
  margin: 0 5px;
  font-weight: bold;
}

@media (max-width: 700px) {
  section > div:nth-child(2){
    flex-direction: column;
  }
  section > div:first-child  > div:first-child{
    display: grid;
    grid-template-columns: fit-content(200px) fit-content(200px);
    grid-template-rows: fit-content(200px) fit-content(200px);
    justify-content: center;
  }

  label {
    margin: auto 0;
  }

  label[name="beginDate"]{
    grid-column: 1;
    grid-row: 1;
    text-align: left;
  }
  input[name="beginDate"]{
    grid-column: 2;
    grid-row: 1;
  }
  label[name="endDate"]{
    grid-column: 1;
    grid-row: 2;
    text-align: left;
  }
  input[name="endDate"]{
    grid-column: 2;
    grid-row: 2;
  }
}
.date-duration-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.date-duration-content div:nth-child(2){
  margin: 0 5px;
  font-weight: normal;
}
</style>